import React from "react"
import { Link } from "gatsby"
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { navigationItems } from "../components/navigation/navigationItems"

// Images
import NotFoundImage from "../images/404.png"

export default function NotFound() {
  return (
    <MainLayout>
      <Seo
        title="Page Not Found | WindUI"
        ogtitle="Page Not Found | WindUI"
        description="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        ogdescription="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        url=" "
        author="Wind UI"
        keywords=""
        robots="index, follow"
        revisit_after="7 days"
      />
      <section className="max-h-screen py-16 lg:py-24">
        <div className="container">
          <div className="grid grid-cols-4 items-center gap-6 lg:grid-cols-12">
            <div className="order-1 col-span-6 pt-6 text-center lg:-order-none lg:m-auto lg:pt-0 lg:text-left ">
              <h1 className="pb-6 text-4xl font-semibold leading-tight text-wuiSlate-900 md:text-5xl lg:text-6xl">
                Page not found!
              </h1>
              <p className="pb-6 text-lg">
                This page does not exist or has been removed! <br />
                <a
                  href="/components/"
                  className="browse_components mt-6 inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiEmerald-500 px-6 font-medium tracking-wide text-white shadow-xl shadow-wuiEmerald-100 transition duration-300 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-600 disabled:cursor-not-allowed disabled:border-wuiEmerald-300 disabled:bg-wuiEmerald-300 disabled:shadow-none"
                >
                  <span>Browse Component</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </span>
                </a>
              </p>
            </div>
            <div className="col-span-6">
              <img src={NotFoundImage} />
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 lg:py-24">
        <div className="container">
          <h2 className="pb-12 text-center text-3xl font-medium text-wuiSlate-800">
            Tailwind CSS Components
          </h2>
          <div className="grid grid-cols-4 items-center gap-x-6 gap-y-12 pb-6 lg:grid-cols-12 lg:pb-12">
            {navigationItems.map((navigationCategory, index) => {
              return navigationCategory.navigationSubCategories.map(
                (navigationSubCategories, index) => {
                  return (
                    navigationSubCategories.featured && (
                      <Link
                        to={navigationSubCategories.subCategoryLink}
                        partiallyActive={true}
                        className={`col-span-4 overflow-hidden  rounded-lg bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 transition-shadow duration-500 hover:shadow-2xl hover:shadow-wuiSlate-300  md:col-span-2 lg:col-span-3 ${
                          navigationSubCategories.comingSoon
                            ? "cursor-no-drop shadow-md shadow-wuiSlate-200 hover:shadow-md hover:shadow-wuiSlate-200"
                            : ""
                        }`}
                        key={index}
                      >
                        {/*    <!-- Card header--> */}

                        <header className="flex flex-col gap-4 px-6 py-4">
                          <h2 className="flex items-center justify-between text-base font-medium text-wuiSlate-700 lg:text-lg">
                            {navigationSubCategories.subCategory}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                              />
                            </svg>
                          </h2>
                        </header>

                        {/*    <!-- Card image --> */}
                        <figure>
                          <img
                            src={navigationSubCategories.subCategoryImage}
                            alt="card image"
                            className="mx-auto aspect-[4/3]"
                          />
                        </figure>
                        <footer className="flex flex-col gap-4 px-6 py-4">
                          <div className="flex items-center justify-between">
                            {navigationSubCategories.subCategoryVarNumber && (
                              <h3 className="text-xs font-medium uppercase leading-5 text-wuiSlate-400">
                                {navigationSubCategories.subCategoryVarNumber}{" "}
                                variations
                              </h3>
                            )}
                            {navigationSubCategories.comingSoon && (
                              <div className="ml-auto rounded-md bg-wuiAmber-50 px-2 py-1 text-xs uppercase text-wuiAmber-500">
                                coming next
                              </div>
                            )}

                            {navigationSubCategories.new && (
                              <div className="rounded-md bg-wuiEmerald-100 px-2 py-1 text-xs uppercase text-wuiEmerald-500">
                                new
                              </div>
                            )}

                            {navigationSubCategories.updated && (
                              <div className="rounded-md bg-wuiCyan-50 px-2 py-1 text-xs uppercase text-wuiCyan-500">
                                updated
                              </div>
                            )}
                          </div>
                        </footer>
                      </Link>
                    )
                  )
                }
              )
            })}
          </div>
          <div className="text-center ">
            <Link
              to="/components/"
              id=""
              className="browse_components inline-flex h-12 items-center justify-center gap-2 whitespace-nowrap rounded bg-wuiEmerald-500 px-6 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiEmerald-300 disabled:bg-wuiEmerald-300 disabled:shadow-none"
            >
              <span>See all components</span>
            </Link>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
